import React, { useEffect } from 'react'
import Layout from "../components/layouts/layout"
import SEO from "../components/layouts/seo"
import AnimatedLink from './../components/global/animatedLink';
import TransitionLink from 'gatsby-plugin-transition-link'
import NotFoundGraphic from '../svg/notfound.svg'

export default function errorPage(props) {
  let { transitionStatus, data } = props;
  
  return (
    <>
      <SEO
        title={'404'} 
        description={'404 Not found'} 
      />
      <Layout
        data={data}
        extraClass={`notfound`}
        transitionStatus={transitionStatus}
      >
        <NotFoundGraphic/>
        <h1>Whoops!</h1>
        <h2>We might of gone a little bit off the beaten track</h2>
        <AnimatedLink to={`/`}/>
        <AnimatedLink 
          className={`cta`} 
          to={`/`} 
          logEvent={`404 - home click`}
        >
          Back home
        </AnimatedLink>
      </Layout>
    </>
  )
}